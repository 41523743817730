@import "color";

.active {
  div, span {
    color: $iBuzzBlack;
    border-color: transparent !important;
  }
  div {
    background: $iBuzzBlackBg2 !important;
  }
}
.disabled {
  color: rgba(0, 0, 0, 0.25)
}