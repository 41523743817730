@import 'color';

.modal {
  max-width: 1200px;

  .container {
    .containerHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid $commonGrey;

      .modalTitle {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .titleText {
          font-size: 16px;
          font-weight: 700;
          line-height: 1;
        }
      }
    }

    .content {
      padding: 20px 24px 20px 12px;
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .row {
        display: grid;
        grid-template-columns: 120px 1fr;
        grid-gap: 28px;
        align-items: center;

        .rowTitle {
          text-align: right;

          &.disabled {
            opacity: 0.4;
          }
        }

        .rowContent {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 8px;

          &.twoBlock {
            grid-template-columns: repeat(2, 1fr);
          }

          &.oneBlock {
            grid-template-columns: repeat(1, 1fr);
          }

          &.autoAndRest {
            grid-template-columns: auto 1fr;
          }

          .normalBtn {
            height: 44px;
            border-color: $iBuzzDeepGrey;

            &:hover {
              color: $iBuzzBlue;
              border-color: $iBuzzBlue;
            }

            &.inActive {
              opacity: 0.4;
            }
          }

          .select {
            height: 44px;
            border-color: $iBuzzDeepGrey;

            &>div {
              border-color: $iBuzzDeepGrey;

              &>span {
                color: $iBuzzBlack;
              }
            }

            &.disabled {
              background: #fff;
              opacity: 0.4;

              &>div {
                background: #fff;
              }
            }
          }

          .noteContainer {
            height: 340px;
            border: 1px dashed $iBuzzGrey;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #b0b1b2;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
          }

          .switch {
            margin: 11px 0px;
            width: 44px;
          }
        }
      }
    }

    .footer {
      padding: 12px 0px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}