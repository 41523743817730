.pageContainer {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #eee;
  .loginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .title {
      font-size: 40px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
      line-height: 1.5;
      white-space: pre-wrap;
      margin-bottom: 50px;
    }
    .img {
      margin-bottom: 30px;
    }
    .btn {
      width: 300px;
      height: 50px;
      line-height: 36px;
      background-color: #1890ff;
      border: none;
      color: #fff;

      font-size: 20px;
      font-weight: 700;
      border-radius: 2px;
      &:hover {
        color: #fff;
        background-color: #1890ff;
        opacity: 0.8;
      }
    }
  }
}