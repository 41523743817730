@import 'color';

.normalButton {
  padding: 14px 15px;
  font-size: 14px;
  line-height: 1;
  color: $iBuzzWhite;
  background: $iBuzzBlue;
  height: unset;
  width: 160px;
  &:hover {
    background: $iBuzzBlue !important;
    opacity: 0.8;
  }
  &:disabled {
    &:hover {
      background: rgba(0, 0, 0, 0.04) !important;
    }
  }
}