@import 'utils';
@import 'color';


.pageContainer {
  width: 100%;
  min-height: 100%;

  padding: 20px 28px 20px 40px;

  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 24px;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pageTitle {
      @include pageTitle;
    }
    
    .backBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
      padding: 4px 15px;
      border: 1px solid $iBuzzBlue;
      border-radius: 8px;
      background: #fff;
      color: $iBuzzBlue;
      width: 120px;
      height: 28px;
      font-size: 14px;
      line-height: 1;
    }
  }


  .contentContainer {
    @include boxOuter;
    padding: 20px 28px;

    .subject {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;
      margin-bottom: 20px;
    }

    .actionRow {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 12px;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px dashed $iBuzzGrey;

      .filterContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 12px;
        row-gap: 12px;

        .datePicker {
          background: $iBuzzBlackBg2;
          width: 250px;
          border: none;

          input::placeholder {
            color: $iBuzzBlack;
          }
        }
      }
    }

    .actions {
      padding: 10px 0px;

      .noteContainer {
        display: flex;
        align-items: center;
        column-gap: 4px;

        .note {
          font-size: 14px;
          font-weight: 700;
          line-height: 1.6;
        }
      }

      .countText {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;
        color: $iBuzzBlack;
      }

      .inputContainer {
        width: 400px;
        padding: 8px 32px;
        background: $iBuzzGrey;
        border-radius: 48px;
        display: flex;
        align-items: center;
        column-gap: 8px;

        input {
          background: $iBuzzGrey;
          flex-grow: 1;
          padding: 0px;
          border: transparent !important;

          &:focus {
            border: transparent !important;
            box-shadow: none !important;
          }

          &::placeholder {
            color: $iBuzzBlack;
            opacity: 0.4;
          }
        }

        .searchIcon {
          cursor: pointer;
        }
      }
    }

    .tableContainer {
      width: 100%;
      border: 1px solid $iBuzzGrey;
      border-radius: 8px;
      overflow: hidden;

      .row {
        min-height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .nameRow {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;

        .nameText {
          color: $iBuzzBlue;
          text-decoration: underline;
        }

        .copiedIcon {
          cursor: pointer;
          color: $iBuzzBlue;
        }
      }

      .idRow {
        .copiedIcon {
          cursor: pointer;
          color: $iBuzzBlue;
          display: inline;
          margin-left: 4px;
        }
      }

      .failStatus {
        color: $iBuzzError
      }

      .actionsRow {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;

        .edit {
          cursor: pointer;
          background-image: url('../../assets/common/edit.svg');
          width: 12px;
          height: 12px;

          &:hover {
            background-image: url('../../assets/common/editActive.svg');
          }
        }

        .redo {
          cursor: pointer;
          background-image: url('../../assets/common/redo.svg');
          width: 12px;
          height: 12px;

          &:hover {
            background-image: url('../../assets/common/redoActive.svg');
          }
        }

        .view {
          cursor: pointer;
          background-image: url('../../assets/common/view.svg');
          width: 12px;
          height: 12px;

          &:hover {
            background-image: url('../../assets/common/viewActive.svg');
          }
        }

        .download {
          cursor: pointer;
          background-image: url('../../assets/common/downloadTwo.svg');
          width: 12px;
          height: 12px;

          &:hover {
            background-image: url('../../assets/common/downloadTwoActive.svg');
          }
        }

        .link {
          cursor: pointer;
          background-image: url('../../assets/common/link.svg');
          width: 12px;
          height: 12px;

          &:hover {
            background-image: url('../../assets/common/linkActive.svg');
          }
        }
      }
    }

  }
}