@import "color";
@import "utils";


.pageContainer {
  width: 100%;
  padding: 32px 28px 32px 40px;

  .contentContainer {
    @include boxOuter;

    .titleContainer {
      padding: 20px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $iBuzzGrey;

      .title {
        display: flex;
        align-items: center;
        column-gap: 4px;


        font-size: 18px;
        font-weight: 700;
        line-height: 1.8;

        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }



    .topicContainer {
      padding: 20px 28px;

      .tableContainer {
        border: 1px solid $iBuzzGrey;
        border-radius: 12px;
        overflow: hidden;

        .row {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 35px;

          &.negative {
            color: $iBuzzError;
            font-weight: 600;
          }
        }

        .oneLineRow {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-align: left;
          white-space: pre-wrap;
          word-break: break-all;

          color: $iBuzzBlue;

          text-decoration: underline;
        }

        .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: auto;

          &.positive {
            background: $iBuzzPositive;
          }

          &.neutral {
            background: $iBuzzNeutral;
          }

          &.negative {
            background: $iBuzzError;
          }
        }
      }
    }
  }
}