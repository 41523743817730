@import "color";

@mixin headerBtn {
  color: $iBuzzWhite;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  &:hover {
    opacity: 0.5;
    color: $iBuzzWhite;
  }
}

@mixin boxOuter {
  border-radius: 8px;
  border: 1px solid $iBuzzGrey;
  background: $iBuzzWhite;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
}