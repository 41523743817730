.main {
  width: 100%;
  height: 100%;
  .lineChartContainer {
    position: relative;
    width: 100%;
  }
  .barChartContainer {
    position: relative;
    width: 100%;
  }
  .wordCloudsContainer {
    .cloudItem {
      margin: 0px auto;
      width: 90%;
      aspect-ratio: 1 / 1;
    }
  }
}
