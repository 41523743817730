@import 'color';

.tagStyle {
  padding: 6px 8px;
  line-height: 1;
  margin-right: 0px;
  border: none;
  background: $iBuzzGrey;
}
.plusTag {
  cursor: pointer;
  background: $iBuzzBlackBg2;
  font-size: 11.5px;
  line-height: 1;
}
.input {
  display: inline-block;
  min-width: 80px;
  width: fit-content;
  font-size: 12px;
  line-height: 1;
  padding: 2px 4px;
}