@import 'color';

.topicListTable {
  tr {
    th {
      font-size: 12px;
      text-align: center !important;
      white-space: pre-wrap !important;
      padding: 16px 0px !important;
      font-weight: 400 !important;
      color: $iBuzzWhite !important;
      background: $iBuzzEDMBg !important;
      &::before {
        width: 0px !important;
      }
    }
    &:first-child {
      // > fixed header will cuz unexpected first row
      td {
        font-size: 12px;
        text-align: center !important;
        white-space: pre-wrap !important;
        padding: 0px 0px !important;
        font-weight: 400 !important;
        &::before {
          width: 0px !important;
        }
      }
    }
    td {
      font-size: 12px;
      text-align: center !important;
      white-space: pre-wrap !important;
      padding: 4px 0px !important;
      font-weight: 400 !important;
      &::before {
        width: 0px !important;
      }
    }
  }
  .ant-switch {
    min-width: 20px;
    height: 14px;
    .ant-switch-handle {
      width: 10px;
      height: 10px;
      inset-inline-start: 2px;
    }
    .ant-switch-inner {
      padding-inline-start: 0px;
      padding-inline-end: 0px;
    }
    &.ant-switch-checked {
      .ant-switch-handle {
        inset-inline-start: calc(100% - 12px);
      }
    }
  }
}

.hideOption {
  display: none !important;
}