@import 'utils';
@import 'color';


.pageContainer {
  width: 100%;
  min-height: 100%;

  padding: 32px 28px 32px 40px;

  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: 24px;

  .titleContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .pageTitle {
      @include pageTitle;

      .timeText {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;
        margin-top: 8px;
        color: $iBuzzBlack;
      }
    }

    .links {
      display: flex;
      align-items: center;
      column-gap: 12px;

      .backBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        padding: 4px 15px;
        border: 1px solid $iBuzzBlue;
        border-radius: 8px;
        background: #fff;
        color: $iBuzzBlue;
        height: 28px;
        font-size: 14px;
        line-height: 1;
      }

      .previewBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        padding: 4px 15px;
        border: 1px solid $iBuzzBlue;
        border-radius: 8px;
        color: #fff;
        background: $iBuzzBlue;
        width: 120px;
        height: 28px;
        font-size: 14px;
        line-height: 1;
      }
    }
  }

  .summaryContainer {
    position: relative;
    @include boxOuter;

    &>.summaryTitle {
      padding: 20px 28px;

      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;

      border-bottom: 1px solid $iBuzzGrey;
    }

    .mainBrandSummaryContainer {
      padding: 20px 0px;
      margin: 0px 28px;
      display: grid;
      grid-template-columns: 1fr auto auto;
      grid-gap: 12px;
      border-bottom: 1px dashed $iBuzzGrey;

      .summaryContent {
        .summaryRow {
          display: flex;

          .summaryTitle {
            flex-shrink: 0;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.8;
          }

          .summaryContent {
            line-height: 1.8;
          }
        }

      }

      .actionBtnsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 12px;
        row-gap: 8px;

        @include l {
          flex-direction: column;
          align-items: flex-end;
        }

        .refreshBtn {
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: $iBuzzBlue;
            border-color: $iBuzzBlue;
            opacity: 0.8;
          }
        }
      }
    }

    .manualSummary {
      padding: 20px 0px;
      margin: 0px 28px;
      border-bottom: 1px dashed $iBuzzGrey;

      .manualTitle {
        display: flex;
        align-items: center;
        column-gap: 4px;

        .titleText {
          font-size: 14px;
          font-weight: 700;
          line-height: 1.8;
        }
      }

      .manualRow {
        display: flex;

        .rowTitle {
          font-size: 14px;
          font-weight: 700;
          line-height: 1.8;
          flex-shrink: 0;
        }

        .rowContent {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.8;
        }
      }
    }

    .memoContainer {
      padding: 20px 28px;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 28px;
      align-items: center;

      .memoTitle {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.6
      }
    }

    .curtain {
      position: absolute;
      border-radius: 8px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $iBuzzGrey;
      opacity: 0.4;
    }
  }


  .topicsContainer {
    @include boxOuter;

    .actionRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 28px;
      border-bottom: 1px solid $iBuzzGrey;

      .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.8;
      }

      .subjectSelect {
        border: 1px solid $iBuzzGrey;
        border-radius: 4px;

        div {
          background: transparent !important;
        }
      }
    }

    .tableContainer {
      margin: 20px 28px;
      width: calc(100% - 56px);
      border: 1px solid $iBuzzGrey;
      border-radius: 8px;
      overflow: hidden;

      .row {
        min-height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-all;
      }

      .oneLineRow {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: left;
        white-space: pre-wrap;
        word-break: break-all;

        color: $iBuzzBlue;

        text-decoration: underline;
      }

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: auto;

        &.positive {
          background: $iBuzzPositive;
        }

        &.neutral {
          background: $iBuzzNeutral;
        }

        &.negative {
          background: $iBuzzError;
        }
      }

      .select {
        max-width: 100%;

        div {
          background: transparent !important;
        }

        span {
          font-size: 12px;
        }

        &.negative {
          span:nth-child(2n) {
            font-weight: 600;
            color: $iBuzzError;
          }
        }
      }

      .switch {
        &.activeSwitch {
          background: $iBuzzBlue;
        }
      }

    }

  }
}

.refreshAutoSummaryModal {
  .refreshContainer {
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;

    .refreshTitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.8;
    }

    .refreshBtnContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      column-gap: 12px;

      .confirmBtn {
        width: 120px;
        height: 44px;
        border: 1px solid $iBuzzBlue;
        color: $iBuzzBlue;

        &:hover {
          border: 1px solid $iBuzzBlue;
          color: $iBuzzBlue;
          opacity: 0.6;
        }
      }

      .cancelBtn {
        width: 120px;
        height: 44px;
        border: 1px solid $iBuzzBlack;
        color: $iBuzzBlack;

        &:hover {
          border: 1px solid $iBuzzBlack;
          color: $iBuzzBlack;
          opacity: 0.6;
        }
      }
    }
  }
}