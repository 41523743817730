@import 'utils';

.pageContainer {
  width: 100%;
  min-height: 100%;

  padding: 32px 28px 32px 40px;

  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 24px;

  .pageTitle {
    @include pageTitle;
  }
}