@import 'color';

// > font size
$pageTitleSize: 24px;

@mixin pageTitle {
  position: relative;
  font-size: $pageTitleSize;
  font-weight: 700;
  line-height: 1.2;
  color: $iBuzzBlue;
  padding-left: 28px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 32px;
    background: $iBuzzBlue;
  }
}

@mixin boxOuter {
  border-radius: 8px;
  border: 1px solid $iBuzzGrey;
  background: $iBuzzWhite;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
}