@import 'color';

.tagStyle {
  padding: 6px 8px;
  line-height: 1;
  margin-right: 0px;
  border: none;
  background: $iBuzzGrey;
  display: flex;
  align-items: center;
  height: min-content;
}
.input {
  display: inline-block;
  min-width: 80px;
  width: fit-content;
  font-size: 12px;
  line-height: 1;
  padding: 2px 4px;
}