@import 'utils';
@import 'color';

.pageContainer {
  width: 100%;
  min-height: 100%;

  padding: 40px 25px 24px;


  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .previewTitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0px 12px 12px;
    .titleText {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
    }
    .dateText {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
    }
  }
  .summaryContainer {
    @include boxOuter;
    overflow: hidden;
    &>.summaryTitle {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 20px 28px;

      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;

      border-bottom: 1px solid $iBuzzGrey;

      background: $iBuzzPreviewBackground;
      color: $iBuzzBlue;
    }
    .mainBrandSummaryContainer {
      padding: 20px 0px;
      margin: 0px 28px;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 12px;
      border-bottom: 1px dashed $iBuzzGrey;
      .summaryContent {
        .summaryRow {
          display: flex;
          align-items: center;
          .summaryTitle {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.8;
          }
        }

      }
      .refreshBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          color: $iBuzzBlue;
          border-color: $iBuzzBlue;
          opacity: 0.8;
        }
       
      }
    }
    .manualSummary {
      padding: 20px 0px;
      margin: 0px 28px;
      border-bottom: 1px dashed $iBuzzGrey;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      .manualTitle {
        display: flex;
        align-items: center;
        column-gap: 4px;
        .titleText {
          font-size: 14px;
          font-weight: 700;
          line-height: 1.8;
        }
      }
      .manualRow {
        display: flex;
        align-items: center;
        .rowTitle  {
          font-size: 14px;
          font-weight: 700;
          line-height: 1.8;
        }
        .rowContent {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.8;
        }
      }
    }
    .memoContainer {
      padding: 20px 28px;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      .memoTitle {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.6
      }
      .memoContent {
        line-height: 1.5;
        white-space: pre-wrap;
      }
    }
  }
  .volumeContainer {
    @include boxOuter;
    overflow: hidden;
    .titleContainer {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 20px 28px;

      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;

      border-bottom: 1px solid $iBuzzGrey;

      background: $iBuzzPreviewBackground;
      color: $iBuzzBlue;
    }
    .volumeContent {
      padding: 20px 28px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .chartContainer {
        border: 1px dashed $iBuzzDeepGrey;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .chartTitle {
          display: flex;
          align-items: center;
          column-gap: 4px;
          padding: 4px 16px;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.6;
        }
        .chartCompareContainer {
          display: flex;
          flex-direction: column;
          row-gap: 6px;

          padding: 4px 16px;
          margin-bottom: 10px;
        }
        .chartDate {
          padding: 4px 16px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .topicsContainer {
    @include boxOuter;
    overflow: hidden;
    .titleContainer {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 20px 28px;

      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;

      border-bottom: 1px solid $iBuzzGrey;

      background: $iBuzzPreviewBackground;
      color: $iBuzzBlue;
    }
    .topicContent {
      padding: 20px 28px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .topicTitle {
        display: flex;
        align-items: center;
        column-gap: 4px;
        
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
      .topicTableContainer {
        border: 1px solid $iBuzzDeepGrey;
        border-radius: 12px;
        overflow: hidden;
        .row {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 35px;
          &.isNegative {
            color: $iBuzzError;
            font-weight: 600;
          }
        }
        .oneLineRow {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-align: left;
          white-space: pre-wrap;
          word-break: break-all;
  
          color: $iBuzzBlue;
  
          text-decoration: underline;
        }
        .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: auto;
          &.positive {
            background: $iBuzzPositive;
          }
          &.neutral {
            background: $iBuzzNeutral;
          }
          &.negative {
            background: $iBuzzError;
          }
        }
      
      }
      .topicPreviewBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 28px;
        padding: 4px 15px;
        margin: 0 auto;

        border: 1px solid $iBuzzDeepGrey;
        border-radius: 8px;
      }
    }
  }
  .featureContainer {
    @include boxOuter;
    overflow: hidden;
    .titleContainer {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 20px 28px;

      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;

      border-bottom: 1px solid $iBuzzGrey;

      background: $iBuzzPreviewBackground;
      color: $iBuzzBlue;
    }
    .contentContainer  {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding: 20px 28px;
      .featureRow {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;
        .cloudContainer {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          padding: 8px;
          border: 1px dashed $iBuzzDeepGrey;
          border-radius: 8px;
          .cloudTitle {
            padding: 4px 0px 4px 16px;
            display: flex;
            align-items: center;
            column-gap: 4px;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.6;
          }
          .dateText {
            padding: 4px 0px 4px 16px;
          }
        }
        .tableContainer {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          padding: 8px;
          border: 1px dashed $iBuzzDeepGrey;
          border-radius: 8px;
          .tableTitle {
            padding: 4px 0px 4px 16px;
            display: flex;
            align-items: center;
            column-gap: 4px;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.6;
          }
          .table {
            border: 1px solid $iBuzzDeepGrey;
            border-radius: 12px;
            overflow: hidden;
            .row {
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 22px;
              .ranking {
                line-height: 20px;
                &.tops {
                  width: 20px;
                  height: 20px;

                  border-radius: 50%;
                  background: $iBuzzBlue;
                  color: #fff;
                }
              }
            }

          }
        }
      }
    }
  }
  .rightContainer {
    padding-top: 12px;
    text-align: center;
    white-space: pre-wrap;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.8;
  }
}