// > color
$iBuzzBlue: #0367A5;
$iBuzzBlack: #393C3E;
$iBuzzGrey: #E4E6E7;
$iBuzzDeepGrey: #A7ABAE;
$iBuzzWhite: #ffffff;
$iBuzzError: #D34C1A;
$iBuzzPositive: #45C0B2;
$iBuzzNeutral: #F2CE16;

// > BG color
$iBuzzBg: #F1F5F9;
$iBuzzBlackBg2: #F5F5F5;
$iBuzzEDMBg: #3A4D63;
