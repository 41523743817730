@import 'color';

.edmListTable {
  tr {
    th {
      font-size: 12px;
      text-align: center !important;
      white-space: pre-wrap !important;
      padding: 16px 0px !important;
      font-weight: 400 !important;
      color: $iBuzzWhite !important;
      background: $iBuzzEDMBg !important;
      &::before {
        width: 0px !important;
      }
    }
    &:first-child {
      // > fixed header will cuz unexpected first row
      td {
        font-size: 12px;
        text-align: center !important;
        white-space: pre-wrap !important;
        padding: 0px 0px !important;
        font-weight: 400 !important;
        &::before {
          width: 0px !important;
        }
      }
    }
    td {
      font-size: 12px;
      text-align: center !important;
      white-space: pre-wrap !important;
      padding: 4px 0px !important;
      font-weight: 400 !important;
      &::before {
        width: 0px !important;
      }
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background: $iBuzzBlue;
    border-color: $iBuzzBlue;
  }
}

.ant-switch {
  &.ant-switch-checked {
    background: $iBuzzBlue;
  }
}