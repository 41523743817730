@import 'color';

.previewTable {
  tr {
    th {
      font-size: 12px;
      text-align: center !important;
      white-space: pre-wrap !important;
      padding: 16px 0px !important;
      font-weight: 400 !important;
      color: $iBuzzWhite !important;
      background: $iBuzzEDMBg !important;
      &::before {
        width: 0px !important;
      }
    }
    &.ant-table-row {
      &:nth-child(2n - 1) {
        td {
          background: $iBuzzBg !important;
        }
      }
    }

    td {
      font-size: 12px;
      text-align: center !important;
      white-space: pre-wrap !important;
      padding: 4px 0px !important;
      font-weight: 400 !important;
      &::before {
        width: 0px !important;
      }
    }
  }
}