.page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .text {
    font-size: 18px;
    font-weight: 700;
  }
}