@import 'color';

.topicRow {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 12px;
  align-items: center;
  .topicContent {
    padding: 0px 16px;
    height: 44px;
    display: flex;
    align-items: center;
    column-gap: 16px;
    border: 1px solid $iBuzzDeepGrey;
    border-radius: 8px;
    .content {
      font-size: 14px;
      font-weight: 400;
      .title {
        font-weight: 700;
      }
    }
    &.error {
      color: $iBuzzError;
    }
  }
  .topicAction {
    width: 20px;
    height: 20px;
    padding: 0px;
    color: $iBuzzBlack
  }
}