@import 'color';

.sourceContainer {
  height: 340px;
  display: grid;
  grid-template-columns: 20fr 21fr 21fr;
  grid-gap: 20px;

  .sourceItem {
    width: 100%;
    height: 100%;
    border: 1px solid $iBuzzDeepGrey;
    border-radius: 12px;
    display: grid;
    grid-template-rows: auto 1fr;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 16px;
      border-bottom: 1px solid $iBuzzDeepGrey;

      .sourceTitle {
        font-size: 14px;
        line-height: 1.8;
        font-weight: 400;
      }

      .selectAllCheckbox {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }



    .sourceContent {
      height: 100%;
      padding: 16px;
      display: grid;
      grid-template-rows: auto 1fr;

      .searchContent {
        border-radius: 48px;
        padding: 8px 32px;
        background: $iBuzzGrey;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 8px;

        .input {
          padding: 0px;
          background: $iBuzzGrey;
          border: none;
          border-color: transparent;
          line-height: 24px;
          height: 24px;

          &:focus {
            border: none;
            border-color: transparent;
            box-shadow: none;
          }
        }

        .searchBtn {
          cursor: pointer;
          height: 24px;
        }
      }

      .emptyContainer {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .treeContainer {
        padding: 12px;
        height: 100%;
        overflow: hidden;

        .levelItem {
          display: flex;
          align-items: center;
          column-gap: 8px;
          margin-bottom: 16px;

          .levelName {
            line-height: 1;
          }
        }
      }
    }
  }
}