@import 'color';

.container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px ;
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.8;
    white-space: pre-wrap;
    text-align: center;
  }
  .content {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 16px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    padding-top: 20px;
    .confirmBtn {
      color: $iBuzzBlue;
      border-color: $iBuzzBlue;
      width: 120px;
      height: 44px;
    }
    .cancelBtn {
      color: $iBuzzBlack;
      border-color: $iBuzzBlack;
      width: 120px;
      height: 44px;
    }
  }
}