@import 'color';

.edmListTable {
  tr {
    th {
      font-size: 12px;
      text-align: center !important;
      white-space: pre-wrap !important;
      padding: 16px 0px !important;
      font-weight: 400 !important;
      color: $iBuzzWhite !important;
      background: $iBuzzEDMBg !important;
      &::before {
        width: 0px !important;
      }
    }
    &:first-child {
      // > fixed header will cuz unexpected first row
      td {
        font-size: 12px;
        text-align: center !important;
        white-space: pre-wrap !important;
        padding: 0px 0px !important;
        font-weight: 400 !important;
        &::before {
          width: 0px !important;
        }
      }
    }
    td {
      font-size: 12px;
      text-align: center !important;
      white-space: pre-wrap !important;
      padding: 4px 0px !important;
      font-weight: 400 !important;
      &::before {
        width: 0px !important;
      }
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background: $iBuzzBlue;
    border-color: $iBuzzBlue;
  }
  &.ant-checkbox-disabled {
    .ant-checkbox-inner {
      background: unset;
      border-color: $iBuzzGrey;
    }
  }
}

.ant-switch {
  &.ant-switch-checked {
    background: $iBuzzBlue;
  }
}

.ant-tree {
  .ant-tree-list {
    .ant-tree-treenode {
      padding-bottom: 8px;
      .ant-tree-checkbox-indeterminate {
        .ant-tree-checkbox-inner {
          &::after {
            background: $iBuzzBlue;
          }
        }
      }
      .ant-tree-checkbox-checked {
        .ant-tree-checkbox-inner {
          background: $iBuzzBlue;
          border-color: $iBuzzBlue;
        }
      }
    }
  }
}