@import 'utils';
@import 'color';

.contentContainer {
  @include boxOuter;
  padding: 20px 28px;

  .actionRow {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;
    padding-bottom: 20px;
    border-bottom: 1px dashed $iBuzzGrey;

    .filterContainer {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 12px;
      align-items: center;

      .selectContainer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 12px;
        row-gap: 12px;
      }
    }
  }

  .actions {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .countText {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.6;
      color: $iBuzzBlack;
    }

    .inputContainer {
      width: 400px;
      padding: 8px 32px;
      background: $iBuzzGrey;
      border-radius: 48px;
      display: flex;
      align-items: center;
      column-gap: 8px;

      input {
        background: $iBuzzGrey;
        flex-grow: 1;
        padding: 0px;
        border: transparent !important;

        &:focus {
          border: transparent !important;
          box-shadow: none !important;
        }

        &::placeholder {
          color: $iBuzzBlack;
          opacity: 0.4;
        }
      }

      .searchIcon {
        cursor: pointer;
      }
    }
  }
  .emptyContainer {
    height: calc(100% - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tableContainer {
    width: 100%;
    border: 1px solid $iBuzzGrey;
    border-radius: 8px;
    overflow: hidden;

    .nameRow {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
      padding: 0px 4px;

      .nameText {
        color: $iBuzzBlue;
        text-decoration: underline;
      }

      .copiedIcon {
        cursor: pointer;
        color: $iBuzzBlue;
      }
    }

    .moreText {
      color: $iBuzzBlue;
      text-decoration: underline;
      cursor: pointer;
    }

    .idRow {
      .copiedIcon {
        cursor: pointer;
        color: $iBuzzBlue;
        display: inline;
        margin-left: 4px;
      }
    }

    .failStatus {
      color: $iBuzzError
    }

    .actionsRow {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;

      .edit {
        cursor: pointer;
        background-image: url('../../../../assets/common/edit.svg');
        width: 12px;
        height: 12px;

        &:hover {
          background-image: url('../../../../assets/common/editActive.svg');
        }
      }

      .redo {
        cursor: pointer;
        background-image: url('../../../../assets/common/redo.svg');
        width: 12px;
        height: 12px;

        &:hover {
          background-image: url('../../../../assets/common/redoActive.svg');
        }
      }

      .delete {
        cursor: pointer;
        background-image: url('../../../../assets/common/delete.svg');
        width: 12px;
        height: 12px;

        &:hover {
          background-image: url('../../../../assets/common/deleteActive.svg');
        }
      }

      .download {
        cursor: pointer;
        background-image: url('../../../../assets/common/download.svg');
        width: 12px;
        height: 12px;

        &:hover {
          background-image: url('../../../../assets/common/downloadActive.svg');
        }
      }
    }
  }

}