@import "color";
@import "utils";

.main {
  overscroll-behavior: none;

  .header {
    width: 100%;
    height: 80px;
    line-height: unset;
    background: $iBuzzEDMBg;
    padding: 0px 28px;

    display: flex;
    align-items: center;

    transition: top .5s linear;

    &.hideHeader {
      top: -80px;
    }

    .headerContent {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .system {
        display: flex;
        align-items: center;
        column-gap: 32px;

        .divider {
          height: 24px;
          width: 2px;
          background: $iBuzzWhite
        }
      }
    }
  }

  .content {
    background: $iBuzzBg;
    position: relative;

    .pageContainer {
      width: 100%;
      padding: 32px 28px 32px 40px;

      @include mobile {
        padding: 12px;
      }

      .contentContainer {
        @include boxOuter;

        .titleContainer {
          padding: 20px 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $iBuzzGrey;

          .title {
            display: flex;
            align-items: center;
            column-gap: 4px;

            font-size: 18px;
            font-weight: 700;
            line-height: 1.8;

            @include mobile {
              padding: 12px;
            }

            .icon {
              width: 20px;
              height: 20px;
            }
          }

          .dateText {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.8;
          }
        }

        .topicContainer {
          padding: 20px 28px;

          @include mobile {
            padding: 12px;
          }

          .tableContainer {
            border: 1px solid $iBuzzGrey;
            border-radius: 12px;
            overflow: hidden;

            .row {
              min-height: 35px;

              &.negative {
                font-weight: 600;
                color: $iBuzzError;
              }
            }

            .titleDeco {
              min-height: 35px;
              color: $iBuzzBlue;

              text-align: left;
              text-decoration: underline;
            }

            .twoLineRow {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              -webkit-box-pack: center;
              white-space: pre-wrap;
              word-break: break-all;

            }

            .dot {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              margin: auto;

              &.positive {
                background: $iBuzzPositive;
              }

              &.neutral {
                background: $iBuzzNeutral;
              }

              &.negative {
                background: $iBuzzError;
              }
            }
          }
        }
      }
    }
  }
}