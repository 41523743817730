@import "color";
@import "utils";

.main {
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;

  .header {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 60px;
    line-height: unset;
    background: $iBuzzEDMBg;
    padding: 0px 28px;

    display: flex;
    align-items: center;

    .headerContent {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .system {
        display: flex;
        align-items: center;

        .divider {
          height: 24px;
          width: 2px;
          margin-left: 32px;
          margin-right: 16px;
          background: $iBuzzWhite
        }

        .btn {
          @include headerBtn
        }

      }

      .actions {
        display: flex;
        align-items: center;

        .btn {
          @include headerBtn
        }

        .divider {
          height: 24px;
          width: 2px;
          background: $iBuzzWhite;
          margin-left: 16px;
          margin-right: 32px;
        }

        .userDropdownContainer {
          display: flex;
          align-items: center;
          column-gap: 4px;
          cursor: pointer;
          .caretDown {
            font-size: 16px;
            color: $iBuzzWhite;
            padding: 0px 4px;
          }
        }
      }
    }
  }
  .content {
    height: 100%;
    overflow: auto;
    background: $iBuzzBg;
  }
}

.userPopoverContainer {
  display: flex;
  flex-direction: column;
  .userContent {
    padding: 16px 32px;
    border-bottom: 1px solid $iBuzzGrey;
  }
  .btn {
    padding: 12px 32px;
    border-bottom: 1px solid $iBuzzGrey;
    transition: opacity .1s linear;
    cursor: pointer;
    &:last-child {
      border: none;
    }
    &:hover {
      opacity: 0.6;
    }
  }

}

.loadingUserContainer {
  height: 100vh;
  width: 100%;
  padding: 80px 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: $iBuzzBg;

  .innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include boxOuter;
  }
}