@import 'color';

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10001;
  &.none {
    display: none;
  }

  .loadingAnimationContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: $iBuzzWhite;
    padding: 0px 150px;
    border-radius: 8px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  }
  .curtain {
    width: 100%;
    height: 100%;
    background: $iBuzzDeepGrey;
    opacity: 0.25;
  }
}